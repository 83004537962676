"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScratchpadMessageCreateRequestSchema = exports.ScratchpadChannelCreateRequestSchema = void 0;
var zod_1 = require("zod");
exports.ScratchpadChannelCreateRequestSchema = zod_1.z.object({
    name: zod_1.z.string(),
});
exports.ScratchpadMessageCreateRequestSchema = zod_1.z.object({
    content: zod_1.z.string(),
});
