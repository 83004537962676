import './baseeditorstyles.css';
import 'highlight.js/styles/vs2015.css';

import {
  AdmonitionDirectiveDescriptor,
  CodeBlockEditorProps,
  codeBlockPlugin,
  codeMirrorPlugin,
  diffSourcePlugin,
  directivesPlugin,
  headingsPlugin,
  imagePlugin,
  KitchenSinkToolbar,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  useCodeBlockEditorContext,
} from '@mdxeditor/editor';
import { styled, Theme } from '@mui/material';
import hljs, { HighlightResult } from 'highlight.js';
import React, { forwardRef } from 'react';

function MDXEditorMarkdown(
  props: React.ComponentProps<typeof MDXEditor> & {
    originalValue?: string;
  },
) {
  const { originalValue, ...rest } = props;

  return (
    <MDXEditor
      {...rest}
      plugins={[
        toolbarPlugin({
          toolbarClassName: 'my-classname',
          toolbarContents: () =>
            (props.readOnly ?? false) ? null : (
              <>
                <KitchenSinkToolbar />
              </>
            ),
        }),
        listsPlugin(),
        quotePlugin(),
        headingsPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        codeBlockPlugin(),
        imagePlugin(),
        tablePlugin(),
        thematicBreakPlugin(),
        codeBlockPlugin({
          defaultCodeBlockLanguage: '',
          codeBlockEditorDescriptors: [
            {
              match: () => props.readOnly ?? false,
              priority: 99999,
              Editor: forwardRef<HTMLDivElement, CodeBlockEditorProps>(
                function Editor(context, ref) {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const editorContext = useCodeBlockEditorContext();

                  // return (
                  //   <TextField
                  //     ref={ref}
                  //     multiline
                  //     fullWidth
                  //     value={context.code}
                  //     onChange={(e) => editorContext.setCode(e.target.value)}
                  //   />
                  // );

                  let html: HighlightResult | null = null;

                  try {
                    if (context.language) {
                      html = hljs.highlight(context.code, {
                        language: context.language,
                      });
                    } else {
                      throw new Error('No language');
                    }
                  } catch {
                    // html = hljs.highlightAuto(context.code);
                    html = hljs.highlight(context.code, {
                      language: 'plaintext',
                    });
                  }

                  return (
                    <div ref={ref} className="hljs">
                      <div
                        style={{
                          zIndex: 9,
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-start',
                          pointerEvents: 'none',
                        }}
                      >
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(context.code);
                          }}
                          style={{
                            background: 'rgba(255,255,255,0.1)',
                            color: 'inherit',
                            border: 'none',
                            cursor: 'pointer',
                            pointerEvents: 'auto',
                          }}
                        >
                          Copy
                        </button>
                      </div>
                      <div
                        ref={ref}
                        dangerouslySetInnerHTML={{ __html: html.value }}
                      />
                    </div>
                  );
                },
              ),
            },
          ],
        }),
        codeMirrorPlugin({
          codeBlockLanguages: {
            '': 'Plain Text',
            typescript: 'TypeScript',
            javascript: 'JavaScript',
            css: 'CSS',
            python: 'Python',
            html: 'HTML',
            sh: 'Shell',
            bash: 'Bash',
            batch: 'Batch',
            c: 'C',
            cpp: 'C++',
            java: 'Java',
            json: 'JSON',
            md: 'Markdown',
            php: 'PHP',
            ruby: 'Ruby',
            rust: 'Rust',
            sql: 'SQL',
            swift: 'Swift',
            yaml: 'YAML',
          },
        }),
        directivesPlugin({
          directiveDescriptors: [AdmonitionDirectiveDescriptor],
        }),
        diffSourcePlugin({
          viewMode: 'rich-text',
          diffMarkdown: originalValue,
        }),
        // eslint-disable-next-line react/prop-types
        ...(props.plugins ?? []),
        markdownShortcutPlugin(),
      ]}
    />
  );
}

export const MDXEditorStyled = styled(MDXEditorMarkdown)(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    theme,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    readOnly,
  }: { theme?: Theme } & React.ComponentProps<typeof MDXEditor>) => `
    --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --font-body: ${theme?.typography.fontFamily};

    --basePageBg: ${theme?.palette.background.default};

    --border-color: ${theme?.palette.divider};

    ${
      theme?.palette.mode === 'dark'
        ? `
          & {
            ${darkColors}
          }
        `
        : `
          & {
            ${lightColors}
          }
        `
    }
  `,
);

const lightColors = `
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce; 
  --blue-12: #113264; 


  --slate-1: #fcfcfd;
  --slate-2: #f9f9fb;
  --slate-3: #f0f0f3;
  --slate-4: #e8e8ec;
  --slate-5: #e0e1e6;
  --slate-6: #d9d9e0;
  --slate-7: #cdced6;
  --slate-8: #b9bbc6;
  --slate-9: #8b8d98;
  --slate-10: #80838d;
  --slate-11: #60646c;  
  --slate-12: #1c2024;  


  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;  
  --red-12: #641723;  


  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #ffee9c;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #e2a336;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ab6400;  
  --amber-12: #4f3422;  


  --cyan-1: #fafdfe;
  --cyan-2: #f2fafb;
  --cyan-3: #def7f9;
  --cyan-4: #caf1f6;
  --cyan-5: #b5e9f0;
  --cyan-6: #9ddde7;
  --cyan-7: #7dcedc;
  --cyan-8: #3db9cf;
  --cyan-9: #00a2c7;
  --cyan-10: #0797b9;
  --cyan-11: #107d98; 
  --cyan-12: #0d3c48; 


  --grass-1: #fbfefb;
  --grass-2: #f5fbf5;
  --grass-3: #e9f6e9;
  --grass-4: #daf1db;
  --grass-5: #c9e8ca;
  --grass-6: #b2ddb5;
  --grass-7: #94ce9a;
  --grass-8: #65ba74;
  --grass-9: #46a758;
  --grass-10: #3e9b4f;
  --grass-11: #2a7e3b;
  --grass-12: #203c25;
`;

const darkColors = `
  --blue-1: #0d1520;
  --blue-2: #111927;
  --blue-3: #0d2847;
  --blue-4: #003362;
  --blue-5: #004074;
  --blue-6: #104d87;
  --blue-7: #205d9e;
  --blue-8: #2870bd;
  --blue-9: #0090ff;
  --blue-10: #3b9eff;
  --blue-11: #70b8ff;
  --blue-12: #c2e6ff; 

  --slate-1: #111113;
  --slate-2: #18191b;
  --slate-3: #212225;
  --slate-4: #272a2d;
  --slate-5: #2e3135;
  --slate-6: #363a3f;
  --slate-7: #43484e;
  --slate-8: #5a6169;
  --slate-9: #696e77;
  --slate-10: #777b84;
  --slate-11: #b0b4ba;
  --slate-12: #edeef0;

  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #551111;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #ff4040;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ff4040;  
  --red-12: #641723;  


  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #504000;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #ffcf40;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ffdf40;  
  --amber-12: #4f3422;  


  --cyan-1: #fafdfe;
  --cyan-2: #f2fafb;
  --cyan-3: #def7f9;
  --cyan-4: #204070;
  --cyan-5: #b5e9f0;
  --cyan-6: #9ddde7;
  --cyan-7: #7dcedc;
  --cyan-8: #a0d0ff;
  --cyan-9: #00a2c7;
  --cyan-10: #0797b9;
  --cyan-11: #a0d0ff; 
  --cyan-12: #0d3c48; 


  --grass-1: #fbfefb;
  --grass-2: #f5fbf5;
  --grass-3: #e9f6e9;
  --grass-4: #005000;
  --grass-5: #c9e8ca;
  --grass-6: #b2ddb5;
  --grass-7: #94ce9a;
  --grass-8: #00ff00;
  --grass-9: #46a758;
  --grass-10: #3e9b4f;
  --grass-11: #00ff00;
  --grass-12: #203c25;
`;
