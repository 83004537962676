import {
  Add,
  Apps,
  ArrowBack,
  AutoAwesome,
  Delete,
  EmojiEmotions,
  Gif,
  Send,
  StickyNote2,
  Tag,
} from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useWorkspaceId } from 'api/providers/workspaceProvider';
import {
  createScratchpadMessage,
  deleteScratchpadMessage,
  useScratchpadChannelMessages,
  useScratchpadChannels,
  useScratchpadMessageDataMaidStatus,
} from 'api/scratchpad';
import { useCreateScratchpadChannelModal } from 'components/createScratchpadChannel';
import { MDXEditorStyled } from 'components/editableTextField/mdxeditor';
import useIsMobile from 'hooks/useIsMobile';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ScratchpadMessage } from 'shared';

export default function ScratchpadPage() {
  const params = useParams();
  const channelId = params.id;
  const workspaceId = useWorkspaceId();

  const navigate = useNavigate();

  const { data: scratchpadChannels } = useScratchpadChannels();
  const { data: scratchpadMessages } = useScratchpadChannelMessages(channelId);

  const { showCreateScratchpadChannelModal } =
    useCreateScratchpadChannelModal(workspaceId);

  useEffect(() => {
    if (!channelId && scratchpadChannels?.length) {
      navigate(
        `/workspace/${workspaceId}/scratchpad/${scratchpadChannels[0].id}`,
        {
          replace: true,
        },
      );
    }
  });

  const currentChannel = useMemo(
    () => scratchpadChannels?.find((c) => c.id === channelId),
    [scratchpadChannels, channelId],
  );

  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const isMobile = useIsMobile();

  const [mobileScreen, setMobileScreen] = useState(isMobile ? 0 : -1);

  const sendMessage = async () => {
    if (!workspaceId || !channelId || sending || !message.trim().length) return;

    setSending(true);
    try {
      await createScratchpadMessage(workspaceId, channelId, {
        content: message,
      });
    } catch {}
    setSending(false);
    setMessage('');
  };

  useEffect(() => {
    if (isMobile) {
      if (mobileScreen === -1) {
        setMobileScreen(1);
      }
    } else {
      setMobileScreen(-1);
    }
  }, [isMobile, mobileScreen]);

  return (
    <Stack direction="row" mx={-3} height="100%" minHeight={0}>
      {mobileScreen !== 1 && (
        <Stack
          flex={`${isMobile ? 1 : 0} 0 250px`}
          borderRight="1px solid"
          borderColor="divider"
        >
          <List dense={!isMobile}>
            <ListItemButton onClick={() => showCreateScratchpadChannelModal()}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary="Add channel" />
            </ListItemButton>
            <Divider />
            {scratchpadChannels?.map((channel) => (
              <ListItemButton
                key={channel.id}
                selected={channel.id === channelId}
                href={`/workspace/${workspaceId}/scratchpad/${channel.id}`}
                onClick={() => setMobileScreen(1)}
              >
                <ListItemIcon>
                  <Tag />
                </ListItemIcon>
                <ListItemText primary={channel.name} />
              </ListItemButton>
            ))}
          </List>
        </Stack>
      )}
      {mobileScreen !== 0 && (
        <Stack flexGrow={1}>
          <Stack direction="row" gap={1} alignItems="center" px={2} pb={1}>
            {isMobile && (
              <IconButton onClick={() => setMobileScreen(0)}>
                <ArrowBack />
              </IconButton>
            )}
            <Typography variant="h6">
              {currentChannel?.name || 'Select a channel'}
            </Typography>
          </Stack>
          <Divider />
          <Stack flexGrow={1} direction="column-reverse" overflow="auto">
            {scratchpadMessages?.length ? (
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
              >
                {scratchpadMessages?.map((message) => (
                  <ListItem key={message.id}>
                    <ListItemAvatar>
                      <Avatar>NA</Avatar>
                    </ListItemAvatar>
                    <Stack flexGrow={1}>
                      <Stack gap={1} direction="row" alignItems="center">
                        <Typography fontWeight="bold">Nasser Akhter</Typography>
                        <Typography variant="body2" color="text.secondary">
                          &mdash;
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {moment(message.created).fromNow()}
                        </Typography>
                        <ScratchpadDataMaidStatus message={message} />
                      </Stack>
                      <MDXEditorStyled
                        readOnly
                        markdown={message.content}
                        sx={{
                          width: '100%',
                          '& p': {
                            m: 0,
                          },
                          '._contentEditable_uazmk_379': {
                            p: 0,
                          },
                          '.mdxeditor-toolbar': {
                            p: '0 !important',
                          },
                          h1: {
                            fontSize: '2rem',
                          },
                          h2: {
                            fontSize: '1.7rem',
                          },
                          h3: {
                            fontSize: '1.5rem',
                          },
                          h4: {
                            fontSize: '1.3rem',
                          },
                          h5: {
                            fontSize: '1.2rem',
                          },
                          h6: {
                            fontSize: '1rem',
                          },
                        }}
                      />
                    </Stack>
                    <ListItemSecondaryAction>
                      <IconButton
                        color="error"
                        onClick={() => {
                          if (!workspaceId) return;
                          deleteScratchpadMessage(
                            workspaceId,
                            message.channel_id,
                            message.id,
                          );
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="caption" textAlign="center" p={2}>
                No messages
              </Typography>
            )}
          </Stack>
          <Stack
            direction="row"
            flexShrink={0}
            p={2}
            gap={1}
            borderTop="1px solid"
            borderColor="divider"
          >
            {false && (
              <IconButton disabled>
                <Add />
              </IconButton>
            )}
            <TextField
              multiline
              fullWidth
              size="small"
              key={channelId}
              autoFocus
              disabled={sending}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (!isMobile && !e.shiftKey && e.key === 'Enter') {
                  sendMessage();
                }
              }}
              placeholder={`Message #${currentChannel?.name}`}
            />
            <IconButton disabled={sending} onClick={() => sendMessage()}>
              <Send />
            </IconButton>
            {false && (
              <>
                <IconButton disabled>
                  <Gif />
                </IconButton>
                <IconButton disabled>
                  <StickyNote2 />
                </IconButton>
                <IconButton disabled>
                  <EmojiEmotions />
                </IconButton>
                <IconButton disabled>
                  <Apps />
                </IconButton>
              </>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function ScratchpadDataMaidStatus(props: { message: ScratchpadMessage }) {
  const { message } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const status = useScratchpadMessageDataMaidStatus(message);

  const createdEntities = useMemo<
    {
      type: string;
      name: string;
    }[]
  >(() => {
    if (!status?.output) {
      return [];
    }

    try {
      const entities = JSON.parse(status.output || '[]');

      return entities;
    } catch {}

    return [];
  }, [status?.output]);

  if (!status?.status) {
    return null;
  }

  return (
    <>
      <IconButton
        size="small"
        sx={{
          color:
            status?.status === 'pending'
              ? 'gray'
              : status?.status === 'processing'
                ? 'pink'
                : status?.status === 'completed'
                  ? 'orange'
                  : status.status === 'ignored'
                    ? 'red'
                    : 'lightgray',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <AutoAwesome />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {createdEntities.length > 0 ? (
          createdEntities.map((entity) => (
            <ListItem key={entity.name}>
              <ListItemText primary={entity.name} secondary={entity.type} />
            </ListItem>
          ))
        ) : status?.status === 'pending' ? (
          <ListItem>
            <ListItemText primary="Pending" />
          </ListItem>
        ) : status?.status === 'processing' ? (
          <ListItem>
            <ListItemText primary="Processing" />
          </ListItem>
        ) : status?.status === 'ignored' ? (
          <ListItem>
            <ListItemText primary="Nothing to do" />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemText primary="No entities created" />
          </ListItem>
        )}
      </Menu>
    </>
  );
}
