import { EndpointUrl } from 'api/endpoint';
import { useEntityFetcher } from 'api/entity';
import { useEffect, useState } from 'react';
import {
  ScratchpadChannel,
  ScratchpadChannelCreateRequest,
  ScratchpadChannelCreateRequestSchema,
  ScratchpadDataMaidStatus,
  ScratchpadMessage,
  ScratchpadMessageCreateRequest,
  ScratchpadMessageCreateRequestSchema,
} from 'shared';
import useSWR, { mutate } from 'swr';

export function useScratchpadChannels() {
  return useEntityFetcher<ScratchpadChannel[]>('scratchpad/channels');
}

export async function createScratchpadChannel(
  workspaceId: string,
  data: ScratchpadChannelCreateRequest,
) {
  const payload = ScratchpadChannelCreateRequestSchema.parse(data);
  const response = await fetch(
    `${EndpointUrl}/workspace/${workspaceId}/scratchpad/channel`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-finsight-token': localStorage.getItem('token') || '',
      },
      body: JSON.stringify(payload),
    },
  );

  mutate(`${EndpointUrl}/workspace/${workspaceId}/scratchpad/channels`);

  return response;
}

export function useScratchpadChannelMessages(channelId: string | undefined) {
  return useEntityFetcher<ScratchpadMessage[]>(
    channelId ? `scratchpad/channel/${channelId}/messages` : null,
  );
}

export async function createScratchpadMessage(
  workspaceId: string,
  channelId: string,
  data: ScratchpadMessageCreateRequest,
) {
  const payload = ScratchpadMessageCreateRequestSchema.parse(data);
  const response = await fetch(
    `${EndpointUrl}/workspace/${workspaceId}/scratchpad/channel/${channelId}/message`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-finsight-token': localStorage.getItem('token') || '',
      },
      body: JSON.stringify(payload),
    },
  );

  mutate(
    `${EndpointUrl}/workspace/${workspaceId}/scratchpad/channel/${channelId}/messages`,
  );

  return response;
}

export async function deleteScratchpadMessage(
  workspaceId: string,
  channelId: string,
  id: string,
) {
  const response = await fetch(
    `${EndpointUrl}/workspace/${workspaceId}/scratchpad/channel/${channelId}/message/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-finsight-token': localStorage.getItem('token') || '',
      },
    },
  );

  mutate(
    `${EndpointUrl}/workspace/${workspaceId}/scratchpad/channel/${channelId}/messages`,
  );

  return response;
}

export function useScratchpadMessageDataMaidStatus(message: ScratchpadMessage) {
  const [cached, setCached] = useState<ScratchpadDataMaidStatus | null>(null);
  const [firstRun, setFirstRun] = useState(true);

  const response = useSWR(
    EndpointUrl && !cached && !firstRun
      ? `${EndpointUrl}/scratchpad/message/${message.id}/datamaid`
      : null,
    async (url) => {
      const response = await fetch(url, {
        headers: {
          'x-finsight-token': localStorage.getItem('token') || '',
        },
      });
      if (!response.ok) return null;
      const data = await response.json();
      return data as ScratchpadDataMaidStatus;
    },
    {
      refreshInterval: 1000,
    },
  );

  useEffect(() => {
    const isCompleted =
      message.status === 'completed' ||
      message.status === null ||
      message.status === 'failed' ||
      message.status === 'ignored' ||
      response.data?.status === 'completed' ||
      response.data?.status === 'failed' ||
      response.data?.status === 'ignored' ||
      response.data?.status === null;

    if (!cached && isCompleted) {
      if (firstRun) {
        setCached({
          id: message.id,
          status: message.status as any,
          output: message.output ?? '',
        });
      } else {
        setCached(response.data ?? null);
      }
    }

    if (firstRun) {
      setFirstRun(false);
    }
  }, [
    cached,
    firstRun,
    message.id,
    message.output,
    message.status,
    response.data,
  ]);

  return cached ? cached : response.data;
}
