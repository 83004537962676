import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Button,
  ButtonBase,
  Container,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

function CheckboxWithLabel(props: { label: string }) {
  const { label } = props;
  const [checked, setChecked] = useState(false);
  return (
    <ButtonBase
      onClick={() => setChecked(!checked)}
      sx={{
        display: 'flex',
        width: 'fit-content',
        py: 1,
        px: 2,
        gap: 1,
        borderRadius: 1,
      }}
    >
      {checked ? (
        <CheckBox color="action" />
      ) : (
        <CheckBoxOutlineBlank color="action" />
      )}
      <Typography>{label}</Typography>
    </ButtonBase>
  );
}

function InputArea(
  props: React.PropsWithChildren & {
    name: string;
    description: string;
  },
) {
  return (
    <Stack gap={1} sx={{}}>
      <Stack>
        <Typography variant="h6">{props.name}</Typography>
        <Typography whiteSpace="break-spaces">{props.description}</Typography>
      </Stack>
      {props.children}
    </Stack>
  );
}

export function FieldJSONGeneratorPage() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Stack gap={5}>
            <InputArea
              name="Name"
              description="The name is used to uniquely identify this field. (Not shown top level)"
            >
              <TextField fullWidth label="Name" />
            </InputArea>
            <InputArea
              name="Type"
              description={`The field type determines the kind of data that can be stored in this field.
String, Number, Boolean, Object, and Date all work according to regular javascript rules.
However, there are a few extra types that Finsight supports:
- Enum: A field that can only take on a limited set of values (array of any regular javascript types)
- Entity: A field that references another entity. At the lowest level this is a string representation of the entity id. However, almost always it will be "displayed" as an object.
- Computed: A field that is calculated based on other fields. This is similar to the =SUM() functions in Excel.`}
            >
              <TextField fullWidth label="Type" select>
                <MenuItem value="string">String</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="boolean">Boolean</MenuItem>
                <MenuItem value="object">Object</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="enum">Enum</MenuItem>
                <MenuItem value="entity">Entity</MenuItem>
                <MenuItem value="computed">Computed</MenuItem>
              </TextField>
            </InputArea>
            <InputArea
              name="Properties"
              description={`Properties are only used when the field type is Object or Enum.
- Object: Properties is an object with keys for each property name and a field definition as the value.
- Enum: Properties is an array of objects with field definitions. Each object should have a name and type field.`}
            >
              <Button>Add New Property</Button>
            </InputArea>
            <Divider>UI Settings</Divider>
            <InputArea
              name="UI Visibility"
              description="Where this field should be displayed"
            >
              <Stack direction="row" gap={1} flexWrap="wrap">
                <CheckboxWithLabel label="Card" />
                <CheckboxWithLabel label="List" />
                <CheckboxWithLabel label="Entity Main" />
                <CheckboxWithLabel label="Entity Sidebar" />
              </Stack>
            </InputArea>
            <Stack
              direction="row"
              gap={2}
              sx={{
                '& > *': {
                  flexGrow: 1,
                },
              }}
            >
              <InputArea
                name="UI Starting Adornment"
                description="The adornment to display at the start of the field"
              >
                <TextField size="small" label="Text" />
                <TextField size="small" label="Icon" />
                <TextField size="small" label="Color" />
              </InputArea>
              <InputArea
                name="UI Ending Adornment"
                description="The adornment to display at the start of the field"
              >
                <TextField size="small" label="Text" />
                <TextField size="small" label="Icon" />
                <TextField size="small" label="Color" />
              </InputArea>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack gap={5}>
            <InputArea
              name="Flags"
              description="Flags that determine how this field behaves"
            >
              <Grid container flexWrap="wrap" gap={1}>
                <CheckboxWithLabel label="Required" />
                <CheckboxWithLabel label="Sortable" />
                <CheckboxWithLabel label="Groupable" />
                <CheckboxWithLabel label="Multiple" />
              </Grid>
              <InputArea
                name="Order"
                description="The order in which this field should appear. All fields of an entity are sorted based off their order value."
              >
                <TextField label="Order" size="small" />
              </InputArea>
            </InputArea>
            <InputArea
              name="Default"
              description="The default value for this field. Leave blank for no default. (Note: required fields will NOT require users to enter a value if a default is set)"
            >
              <TextField label="Value" />
            </InputArea>
            <InputArea
              name="Display Name"
              description="The name to display to users"
            >
              <TextField label="Display Name" />
            </InputArea>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

/* <InputArea
              name="Constraints"
              description="Constraints on the field"
            >
              <Typography>Constraints</Typography>
              <Stack>
                <TextField label="Name" />
                <TextField label="Property" />
                <TextField label="Operator" select>
                  <MenuItem value="equals">Equals</MenuItem>
                  <MenuItem value="not_equals">Not Equals</MenuItem>
                  <MenuItem value="in">In</MenuItem>
                  <MenuItem value="not_in">Not In</MenuItem>
                </TextField>
                <Stack>
                  <Typography>Value</Typography>
                  <Stack>
                    <Typography>Array?</Typography>
                    <Checkbox />
                  </Stack>
                  <Stack>
                    <TextField label="Type" select>
                      <MenuItem value="string">String</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="boolean">Boolean</MenuItem>
                    </TextField>
                    <TextField label="Value" />
                  </Stack>
                </Stack>
              </Stack>
              <Button>Add New Constraint</Button>
            </InputArea> */
