import { AccountTree, Article } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useWorkspace } from 'api/providers/workspaceProvider';
import CardButton from 'components/cardButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const workspace = useWorkspace();
  const navigate = useNavigate();

  return (
    <Stack>
      <Typography>{workspace?.name}</Typography>
      <Typography variant="h4">Home</Typography>
      <Stack
        mt={3}
        gap={2}
        alignItems="flex-start"
        direction="row"
        flexWrap="wrap"
      >
        <CardButton
          icon={<AccountTree />}
          title="Projects"
          description="Manage your projects"
          onClick={() => navigate(`/workspace/${workspace?.id}/projects`)}
        />
        <CardButton
          icon={<Article />}
          title="Scratchpad"
          description="Quickly take notes"
          onClick={() => navigate(`/workspace/${workspace?.id}/scratchpad`)}
        />
      </Stack>
    </Stack>
  );
}
